import React, { useDebugValue, useEffect, useState } from "react";

export const useLocalStorage = (key, initialState) => {
  const [state, setState] = useState(initialState);
  useDebugValue(state);

  useEffect(() => {
    const item = localStorage.getItem(key);
    if (item) setState(parse(item));
  }, []);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [state]);

  return [state, setState];
};

const parse = (value) => {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

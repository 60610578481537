import { MEDIUM } from "shared/variables";
import cx from "classnames";
import styles from "./SpacingSection.module.css";

export const SpacingSection = ({
  className = undefined,
  children,
  size = MEDIUM,
  onlyBottom = false,
}) => (
  <div
    className={cx(
      styles.container,
      styles[size],
      {
        [styles.onlyBottom]: onlyBottom,
      },
      className
    )}
  >
    {children}
  </div>
);

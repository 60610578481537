import { SMALL, MEDIUM, LARGE, EXTRALARGE } from "shared/variables";
import cx from "classnames";
import styles from "./MaxWidth.module.css";

export const MaxWidth = ({ children, size = MEDIUM }) => (
  <div
    className={cx(styles.container, {
      [styles.small]: size === SMALL,
      [styles.large]: size === LARGE,
      [styles.extraLarge]: size === EXTRALARGE,
    })}
  >
    {children}
  </div>
);

export const IconCalendar = (props) => (
  <Icon {...props}>
    <path d="M30 21h-5v-5h5v5zm-7 0h-5v-5h5v5zm0 7h-5v-5h5v5zm-7-7h-5v-5h5v5zm0 7h-5v-5h5v5zM28.273 9.381V7h-2.364v5.952h-2.364V9.381h-7.09V7H14.09v5.952h-2.364V9.381H9.364A2.375 2.375 0 007 11.761V29.62A2.375 2.375 0 009.364 32h21.272A2.375 2.375 0 0033 29.619V11.762a2.375 2.375 0 00-2.364-2.381h-2.363z" />
  </Icon>
);

export const IconWebsite = (props) => (
  <Icon {...props}>
    <path d="M34 22V9.308C34 8.035 32.953 7 31.667 7H8.333C7.046 7 6 8.035 6 9.308V22h28zM6 24v1.286c0 1.418 1.046 2.571 2.333 2.571h10.5v2.572h-4.666V33h11.666v-2.571h-4.666v-2.572h10.5c1.286 0 2.333-1.153 2.333-2.571V24H6z" />
  </Icon>
);

export const IconCheck = (props) => (
  <Icon {...props}>
    <path
      fill="#39B54A"
      d="M40 20c0 3.628-.894 6.975-2.682 10.04a19.91 19.91 0 01-7.279 7.278C26.975 39.106 23.63 40 20 40c-3.628 0-6.975-.894-10.04-2.682a19.91 19.91 0 01-7.278-7.279C.894 26.975 0 23.63 0 20c0-3.628.894-6.975 2.682-10.04a19.91 19.91 0 017.279-7.278C13.025.894 16.37 0 20 0c3.628 0 6.975.894 10.04 2.682a19.91 19.91 0 017.278 7.279C39.106 13.025 40 16.37 40 20z"
    />
    <path
      fill="#FFF"
      d="M31.667 16.724c0-.409-.126-.744-.378-1.007l-1.91-1.968a1.266 1.266 0 00-.946-.416c-.363 0-.678.139-.944.416l-8.566 8.902-4.745-4.943a1.266 1.266 0 00-.945-.416c-.364 0-.679.139-.945.416l-1.91 1.968c-.252.263-.378.598-.378 1.006 0 .394.126.722.378.985l7.6 7.917c.266.277.58.416.945.416.378 0 .7-.139.966-.416l11.4-11.876c.252-.263.378-.59.378-.984z"
    />
  </Icon>
);

export const IconChevron = (props) => (
  <Icon {...props}>
    <path d="M0 13.333L20 32l20-18.667L34.667 8 20 21.333 5.333 8z" />
  </Icon>
);

export const IconClose = (props) => (
  <Icon {...props}>
    <path d="M6.275 2.126l31.393 31.806a1.5 1.5 0 010 2.107l-2.08 2.108a1.5 1.5 0 01-2.136 0L2.06 6.34a1.5 1.5 0 010-2.108l2.08-2.107a1.5 1.5 0 012.135 0z" />
    <path d="M2.06 33.932L33.452 2.126a1.5 1.5 0 012.136 0l2.08 2.107a1.5 1.5 0 010 2.108L6.275 38.147a1.5 1.5 0 01-2.135 0l-2.08-2.108a1.5 1.5 0 010-2.107z" />
  </Icon>
);

export const IconDownArrow = (props) => (
  <Icon {...props}>
    <path d="M17.143 3.143V30.57l-6.6-6.6c-.563-.562-1.234-.828-1.972-.828-1.405 0-2.857 1.161-2.857 2.857 0 .759.276 1.42.829 1.971L17.91 39.34c.469.469 1.048.946 2.089.946s1.543-.399 2.067-.923l11.39-11.392c.553-.551.829-1.212.829-1.971 0-1.697-1.452-2.857-2.857-2.857-.738 0-1.409.266-1.972.828l-6.6 6.6V3.143a2.858 2.858 0 00-5.714 0z" />
  </Icon>
);

export const IconHamburger = (props) => (
  <Icon {...props}>
    <rect y="8" width="40" height="5" rx="1.5" />
    <rect y="18" width="40" height="5" rx="1.5" />
    <rect y="28" width="40" height="5" rx="1.5" />
  </Icon>
);

export const IconStar = (props) => (
  <Icon {...props}>
    <path d="M20 31.544L7.64 38.042 10 24.278 0 14.531l13.82-2.008L20 0l6.18 12.523L40 14.53l-10 9.747 2.36 13.764z" />
  </Icon>
);

export const IconFacebook = (props) => (
  <Icon {...props}>
    <path d="M29.292.288v6.347h-3.688c-1.346 0-2.254.288-2.724.865-.47.577-.705 1.442-.705 2.596v4.543h6.882l-.916 7.116h-5.966V40H14.99V21.755H9v-7.116h5.989V9.4c0-2.98.814-5.292 2.442-6.935C19.06.82 21.228 0 23.937 0c2.301 0 4.086.096 5.355.288z" />
  </Icon>
);

export const IconLinkedin = (props) => (
  <Icon {...props}>
    <path d="M8.953 39.991H.661v-26.7h8.292v26.7zM4.803 9.65C2.15 9.65 0 7.454 0 4.802A4.804 4.804 0 014.802 0a4.804 4.804 0 014.803 4.802c0 2.652-2.151 4.848-4.803 4.848zM39.99 39.99h-8.275V26.994c0-3.098-.062-7.07-4.311-7.07-4.312 0-4.973 3.365-4.973 6.847v13.22H14.15v-26.7h7.953v3.643h.116c1.107-2.098 3.812-4.312 7.847-4.312 8.39 0 9.935 5.526 9.935 12.703V39.99h-.009z" />
  </Icon>
);

export const IconTwitter = (props) => (
  <Icon {...props}>
    <path d="M38.914 7.717a16.626 16.626 0 01-4 4.085A22.243 22.243 0 0134 19.176c-.626 2.11-1.576 4.136-2.852 6.077a23.962 23.962 0 01-4.555 5.148c-1.762 1.492-3.885 2.682-6.37 3.57-2.486.89-5.145 1.333-7.976 1.333-4.461 0-8.543-1.182-12.247-3.546.576.065 1.218.098 1.926.098 3.704 0 7.004-1.125 9.901-3.375-1.728-.033-3.276-.558-4.642-1.577-1.366-1.02-2.304-2.32-2.815-3.901.544.081 1.046.122 1.507.122.707 0 1.407-.09 2.098-.269-1.843-.375-3.37-1.284-4.58-2.727-1.21-1.443-1.815-3.118-1.815-5.026v-.098a7.97 7.97 0 003.605 1.003 7.919 7.919 0 01-2.592-2.812 7.64 7.64 0 01-.963-3.767c0-1.434.362-2.763 1.086-3.986 1.992 2.43 4.416 4.374 7.272 5.833 2.856 1.46 5.913 2.27 9.172 2.433a8.694 8.694 0 01-.197-1.81c0-2.184.778-4.047 2.333-5.588C22.852 4.771 24.732 4 26.938 4c2.305 0 4.247.832 5.827 2.495a15.73 15.73 0 005.062-1.908c-.609 1.875-1.778 3.326-3.506 4.353 1.53-.163 3.062-.57 4.593-1.223z" />
  </Icon>
);

export const IconYoutube = (props) => (
  <Icon {...props}>
    <path d="M28.571 20.4c0-.555-.223-.96-.67-1.215l-11.428-7.2c-.461-.3-.945-.315-1.45-.045-.492.27-.737.69-.737 1.26v14.4c0 .57.245.99.736 1.26.238.12.47.18.692.18.298 0 .55-.075.76-.225l11.428-7.2c.446-.255.67-.66.67-1.215zM40 20.4c0 1.44-.007 2.565-.022 3.375s-.078 1.834-.19 3.071a25.053 25.053 0 01-.502 3.319 5.274 5.274 0 01-1.54 2.767c-.79.75-1.712 1.185-2.768 1.305-3.304.376-8.296.563-14.978.563s-11.674-.187-14.978-.563c-1.056-.12-1.983-.554-2.779-1.305a5.241 5.241 0 01-1.551-2.767 28.058 28.058 0 01-.48-3.319 44.484 44.484 0 01-.19-3.071C.007 22.965 0 21.84 0 20.4s.007-2.565.022-3.375.078-1.834.19-3.071c.112-1.238.28-2.344.502-3.319a5.274 5.274 0 011.54-2.768c.79-.75 1.712-1.184 2.768-1.304C8.326 6.186 13.318 6 20 6s11.674.187 14.978.563c1.056.12 1.983.554 2.779 1.304a5.241 5.241 0 011.551 2.768c.208.975.368 2.081.48 3.319.112 1.237.175 2.261.19 3.071.015.81.022 1.935.022 3.375z" />
  </Icon>
);

export const IconCosts = (props) => (
  <Icon {...props}>
    <path d="M20 6C12.28 6 6 12.28 6 20c0 7.721 6.28 14 14 14s14-6.279 14-14c0-7.72-6.28-14-14-14zm-1.318 12.6H23v2.8h-4.318c.582 2.258 2.583 2.8 4.318 2.8V27c-3.274 0-6.741-1.51-7.384-5.6H14v-2.8h1.616c.643-4.09 4.11-5.6 7.384-5.6v2.8c-1.735 0-3.736.542-4.318 2.8z" />
  </Icon>
);

export const IconDuration = (props) => (
  <Icon {...props}>
    <path d="M32.188 14.797a4 4 0 00-2.855-6.799c-1.089 0-2.077.44-2.798 1.147l5.653 5.652zM7.812 14.797a4 4 0 012.855-6.799c1.089 0 2.077.44 2.798 1.147l-5.653 5.652zM20 9.333c-6.616 0-12 5.384-12 12s5.384 12 12 12 12-5.384 12-12-5.384-12-12-12zm3.057 16.942l-4-4c-.249-.25-.39-.588-.39-.942v-6.666h2.666v6.114l3.61 3.61-1.886 1.884z" />
  </Icon>
);

export const IconInvestment = (props) => (
  <Icon {...props}>
    <path d="M15 12h10l-5 5-5-5zm-4 18.658c0 .659.576 1.193 1.286 1.193h15.428c.71 0 1.286-.534 1.286-1.193v-2.59c0-.942-.411-1.863-1.13-2.53l-6.052-5.613 6.052-5.613c.729-.676 1.13-1.574 1.13-2.53v-2.59C29 8.535 28.424 8 27.714 8H12.286C11.576 8 11 8.534 11 9.193v2.589c0 .956.401 1.854 1.13 2.529l6.052 5.614-6.052 5.614c-.719.666-1.13 1.587-1.13 2.53v2.59z" />
  </Icon>
);

export const IconLevel = (props) => (
  <Icon {...props}>
    <path d="M28.25 13.2h-16.5v-2.6h16.5v2.6zm-4.125 14.3L20 24.9l-4.125 2.6 1.375-3.9L14.5 21h4.125L20 17.1l1.375 3.9H25.5l-2.75 2.6 1.375 3.9zM28.25 8h-16.5C10.233 8 9 9.166 9 10.6v13c0 5.888 9.389 9.862 10.458 10.295.174.071.358.105.542.105.184 0 .368-.034.542-.105C21.612 33.462 31 29.488 31 23.6v-13C31 9.166 29.767 8 28.25 8z" />
  </Icon>
);

export const IconLocation = (props) => (
  <Icon {...props}>
    <path d="M15.417 16.588c0-2.435 2.053-4.412 4.583-4.412 2.53 0 4.583 1.977 4.583 4.412C24.583 19.024 22.53 21 20 21c-2.53 0-4.583-1.976-4.583-4.412m15.583 0C31 10.74 26.074 6 20 6 13.924 6 9 10.74 9 16.588 9 23.648 20 36 20 36s11-12.353 11-19.412" />
  </Icon>
);

export const IconMail = (props) => (
  <Icon {...props}>
    <path d="M20.054 19.818L31 15.332V13H9v2.325z" />
    <path d="M20.06 23.056L9 18.455V28h22v-9.53z" />
  </Icon>
);

export const IconParticipants = (props) => (
  <Icon {...props}>
    <path d="M27.273 7.91c1.2 0 2.182.982 2.182 2.18a2.19 2.19 0 01-2.182 2.183 2.19 2.19 0 01-2.182-2.182 2.19 2.19 0 012.182-2.182M30.182 22.455h-5.818v-5.819a2.91 2.91 0 015.818 0v5.819zM20 5c1.2 0 2.182.983 2.182 2.182A2.19 2.19 0 0120 9.364a2.19 2.19 0 01-2.182-2.182A2.19 2.19 0 0120 5M22.91 19.545h-5.82v-5.818a2.91 2.91 0 015.82 0v5.818zM12.727 7.91c1.2 0 2.182.982 2.182 2.18a2.19 2.19 0 01-2.182 2.183 2.19 2.19 0 01-2.182-2.182 2.19 2.19 0 012.182-2.182M15.636 22.455H9.818v-5.819a2.91 2.91 0 015.818 0v5.819zM20 23.91c-8.836 0-16 1.953-16 4.363s7.164 4.363 16 4.363c8.835 0 16-1.953 16-4.363s-7.165-4.364-16-4.364" />
  </Icon>
);

export const IconPhone = (props) => (
  <Icon {...props}>
    <path d="M22.294 31h5.588C28.5 31 29 30.448 29 29.765v-4.941c0-.684-.5-1.236-1.118-1.236h-4.47c-.618 0-1.118.552-1.118 1.236v1.235c-4.47 0-7.753-3.783-7.823-8.647h1.117c.618 0 1.118-.552 1.118-1.236v-4.94c0-.684-.5-1.236-1.118-1.236h-4.47C10.5 10 10 10.552 10 11.235v6.177C10 24.916 15.504 31 22.294 31" />
  </Icon>
);

export const IconStart = (props) => (
  <Icon {...props}>
    <rect x="9" y="8" width="3" height="24" rx="1.5" />
    <path d="M15.585 9.077A1.165 1.165 0 0014 10.166v11.666A1.163 1.163 0 0015.167 23c.14 0 .282-.027.418-.077l15.167-5.834a1.166 1.166 0 000-2.178L15.586 9.077z" />
  </Icon>
);

export const IconWhatsapp = (props) => (
  <Icon {...props}>
    <path d="M20.218 31.382c-2.066 0-4.007-.528-5.695-1.455L8 32l2.126-6.273a11.572 11.572 0 01-1.69-6.036C8.436 13.234 13.711 8 20.218 8S32 13.234 32 19.69c0 6.458-5.275 11.692-11.782 11.692zm0-21.52c-5.462 0-9.905 4.41-9.905 9.829 0 2.15.7 4.142 1.886 5.763l-1.237 3.65 3.806-1.21a9.9 9.9 0 005.45 1.626c5.462 0 9.906-4.41 9.906-9.829 0-5.42-4.444-9.83-9.906-9.83zm5.95 12.521c-.073-.119-.266-.19-.554-.334-.289-.143-1.71-.837-1.973-.932-.266-.095-.458-.144-.65.143-.193.287-.746.932-.915 1.123-.169.192-.337.216-.626.072-.288-.143-1.22-.445-2.323-1.422-.858-.76-1.438-1.697-1.606-1.984-.168-.287-.018-.442.127-.584.13-.129.289-.335.433-.502.145-.168.193-.287.289-.478.096-.191.048-.359-.024-.502-.073-.144-.65-1.554-.891-2.128-.24-.573-.48-.477-.65-.477-.168 0-.36-.024-.553-.024-.193 0-.506.071-.77.358-.265.287-1.011.98-1.011 2.39 0 1.41 1.034 2.773 1.18 2.964.143.19 1.996 3.178 4.932 4.326 2.937 1.147 2.937.764 3.467.717.528-.048 1.708-.694 1.949-1.363.24-.67.24-1.243.168-1.363z" />
  </Icon>
);

const Icon = (props) => (
  <svg viewBox="0 0 40 40" fill="currentColor" {...props}>
    {props.children}
  </svg>
);

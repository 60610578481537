import { useEffect, useState } from "react";

const TABLET = "TABLET";
const DESKTOP = "DESKTOP";
const MOBILE = "MOBILE";

const matchMedia = (minWidth) =>
  window.matchMedia && window.matchMedia(`(min-width: ${minWidth}px)`).matches;

const getBreakPoint = () => {
  if (matchMedia(1024)) return DESKTOP;
  if (matchMedia(768)) return TABLET;
  return MOBILE;
};

export function useBreakpoint() {
  const [breakpoint, setBreakpoint] = useState(MOBILE);

  useEffect(() => {
    const onResize = () => setBreakpoint(getBreakPoint());

    window.addEventListener("resize", onResize, { passive: true });
    onResize();

    return () => window.removeEventListener("resize", onResize);
  }, []);

  return {
    breakpoint,
    isDesktop: breakpoint === DESKTOP,
    isTablet: breakpoint === TABLET,
    isMobile: breakpoint === MOBILE,
  };
}

import { Button } from "components/Button";
import { SMALL, GREEN } from "shared/variables";
import styles from "./MessageBar.module.css";

export const MessageBar = ({ emoji, text, buttonLabel, onButtonClick }) => (
  <div className={styles.container}>
    <div className={styles.emoji}>{emoji}</div>
    <div className={styles.text}>{text}</div>
    <div className={styles.buttonContainer}>
      <Button onClick={onButtonClick} color={GREEN} size={SMALL}>
        {buttonLabel}
      </Button>
    </div>
  </div>
);

import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { IconChevron, IconClose, IconHamburger } from "components/Icons";
import { Link } from "components/Link";
import { linkResolver } from "shared/prismic";
import { Logo } from "components/Logo";
import { useAppContext } from "shared/useAppContext";
import { useBreakpoint } from "shared/useBreakpoint";
import { useRouter } from "next/router";
import { useState, useEffect, useRef } from "react";
import cx from "classnames";
import styles from "./Nav.module.css";

export const Nav = () => {
  const { navigation } = useAppContext();
  const breakpoint = useBreakpoint();
  const router = useRouter();

  const [isOpen, setIsOpen] = useState(false);
  const navigationRef = useRef(null);

  const menuItems = navigation.data.body.map((item) => ({
    isActive: isActive(item.primary.link),
    items: item.items.map((subItem) => ({
      isActive: isActive(subItem.href),
      label: subItem.label,
      type: subItem.href.type,
      uid: subItem.href.uid,
    })),
    label: item.primary.label,
    type: item.primary.link.type,
    uid: item.primary.link.uid,
  }));

  function isActive({ type, uid }) {
    const href = linkResolver({ type, uid });

    if (href === "/") {
      return router.pathname === "/";
    } else {
      if (href === router.pathname) return true; // for static pages
      if (router.asPath.indexOf(href) === 0) return true; // for [...slug]
    }

    return false;
  }

  useEffect(() => {
    if (breakpoint.isDesktop) {
      clearAllBodyScrollLocks();
      setIsOpen(false);
      return;
    }

    if (isOpen) {
      disableBodyScroll(navigationRef.current);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [breakpoint.isDesktop, isOpen]);

  useEffect(() => {
    setIsOpen(false);
  }, [router.asPath]);

  return (
    <div className={styles.container}>
      <Link {...{ type: "homepage", uid: "homepage" }} className={styles.logo}>
        <Logo />
      </Link>

      <button
        type="button"
        className={styles.menuButton}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{isOpen ? " Sluiten" : "Menu"}</span>
        {isOpen ? <IconClose /> : <IconHamburger />}
      </button>

      <div
        className={cx(styles.navItemsContainer, { [styles.isOpen]: isOpen })}
        ref={navigationRef}
      >
        {menuItems.map(({ isActive, items, label, type, uid }) => (
          <div key={uid} className={styles.navItemGroup}>
            <Link
              className={cx(styles.navItem, {
                [styles.isActive]: isActive,
                [styles.hasSub]: items.length > 0,
              })}
              key={createKey({ type, uid })}
              {...{ type, uid }}
            >
              <IconChevron />
              <span>{label}</span>
            </Link>
            {items.length > 0 && (
              <div className={styles.subNavItemsContainer}>
                {items.map(({ isActive, label, type, uid }) => (
                  <Link
                    className={cx(styles.subNavItem, {
                      [styles.isActive]: isActive,
                    })}
                    key={createKey({ type, uid })}
                    {...{ type, uid }}
                  >
                    {label}
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

function createKey({ type, uid }) {
  return `${type}-${uid}`;
}

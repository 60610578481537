import { hrefResolver, linkResolver } from "shared/prismic";
import NextLink from "next/link";

export const Link = ({
  as: Element = undefined,
  children = undefined,
  isBroken = undefined,
  link_type = undefined,
  type = undefined,
  uid = undefined,
  url = undefined,
  ...rest
}) => {
  if (link_type === "Media" || link_type === "Web" || url) {
    if (!url) return null;
    return (
      <Element
        href={url}
        target={url?.includes("groundwork.nl") ? "_self" : "_blank"}
        rel="noopener noreferrer"
        {...rest}
      >
        {children}
      </Element>
    );
  }

  return (
    <NextLink
      legacyBehavior
      as={linkResolver({ type, uid })}
      // @ts-ignore
      href={hrefResolver({ type, uid })}
    >
      <Element {...rest}>{children}</Element>
    </NextLink>
  );
};

Link.defaultProps = {
  as: "a",
};

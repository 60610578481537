import { EXTRALARGE } from "shared/variables";
import {
  IconFacebook,
  IconLinkedin,
  IconTwitter,
  IconYoutube,
} from "components/Icons";
import { Link } from "components/Link";
import { Logo } from "components/Logo";
import { MaxWidth } from "components/MaxWidth";
import { useAppContext } from "shared/useAppContext";
import cx from "classnames";
import styles from "./Footer.module.css";

export const Footer = ({ isLight }) => {
  const { footerNavigation } = useAppContext();

  const {
    course_links,
    other_links,
    privacy_link,
    terms_link,
    training_links,
  } = footerNavigation.data;

  return (
    <footer className={cx(styles.container, { [styles.isLight]: isLight })}>
      <MaxWidth size={EXTRALARGE}>
        <div className={styles.contentContainer}>
          <div className={styles.logoContainer}>
            <Logo />
          </div>
          <div>
            <LinksWithTitle
              title="Trainingen"
              links={training_links.map(({ link, label }) => ({ link, label }))}
            />
            <LinksWithTitle
              title="Opleidingen"
              links={course_links.map(({ link, label }) => ({ link, label }))}
            />
          </div>
          <div>
            <LinksWithTitle
              title="Ontdek ons"
              links={other_links.map(({ link, label }) => ({ link, label }))}
            />
          </div>
          <div className={styles.socialContainer}>
            <a
              className={styles.socialIcon}
              href="https://www.facebook.com/Groundwork.nl/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ backgroundColor: "#3B5998" }}
            >
              <IconFacebook />
            </a>
            <a
              className={styles.socialIcon}
              href="https://twitter.com/groundworknl"
              target="_blank"
              rel="noopener noreferrer"
              style={{ backgroundColor: "#3C93D5" }}
            >
              <IconTwitter />
            </a>
            <a
              className={styles.socialIcon}
              href="https://www.linkedin.com/school/groundwork-nl"
              target="_blank"
              rel="noopener noreferrer"
              style={{ backgroundColor: "#0077B5" }}
            >
              <IconLinkedin />
            </a>
            <a
              className={styles.socialIcon}
              href="https://www.youtube.com/channel/UCGXaLFathZsld3HGBIfR9oQ"
              target="_blank"
              rel="noopener noreferrer"
              style={{ backgroundColor: "#FF0000" }}
            >
              <IconYoutube />
            </a>
          </div>
        </div>
        <div className={styles.footer}>
          <div>
            &copy; {new Date().getFullYear()} Groundwork. Alle rechten
            voorbehouden
          </div>
          <div>
            <Link {...{ uid: privacy_link.uid, type: privacy_link.type }}>
              Privacy
            </Link>{" "}
            ·{" "}
            <Link {...{ uid: terms_link.uid, type: terms_link.type }}>
              Algemene voorwaarden 
            </Link>{" "}
            ·{" "}
            <Link {...{ uid: "klachtenregeling", type: "page" }}>
              Klachtenregeling
            </Link>
          </div>
          <a
            href="https://eight.nl/"
            rel="noopener noreferrer"
            target="_blank"
            className={styles.eightLogoContainer}
          >
            UX Design & Development
            <span className={styles.eightLogo}>
              <EightLogo />
            </span>
          </a>
        </div>
      </MaxWidth>
    </footer>
  );
};

const LinksWithTitle = ({ links, title }) => (
  <>
    <h4 className={styles.title}>{title}</h4>
    <ul className={styles.list}>
      {links.map(({ link: { type, uid, data }, label }, index) => (
        <li key={index}>
          <Link className={styles.link} {...{ type, uid }}>
            {label}
          </Link>
        </li>
      ))}
    </ul>
  </>
);

const EightLogo = () => (
  <svg viewBox="0 0 77 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M53.118 7.391c.809 0 1.507.118 2.094.356a5.685 5.685 0 011.608.984l-1.485 1.796a3.956 3.956 0 00-1.01-.643 2.939 2.939 0 00-1.193-.224 2.17 2.17 0 00-.92.195 2.325 2.325 0 00-.736.538 2.61 2.61 0 00-.678 1.783v.026a2.79 2.79 0 00.181 1.022c.124.315.295.584.519.806.223.223.483.4.784.526.298.125.635.188 1.002.188.592 0 1.078-.126 1.464-.38v-1.113h-1.8v-1.86l4.2-.003v4.129a6.636 6.636 0 01-1.722 1.01c-.663.274-1.408.407-2.243.408-.714-.001-1.37-.114-1.974-.347a4.722 4.722 0 01-1.574-.98 4.506 4.506 0 01-1.04-1.498 4.694 4.694 0 01-.379-1.918v-.029c0-.663.123-1.288.37-1.869a4.799 4.799 0 011.016-1.522 4.66 4.66 0 011.556-1.014 5.162 5.162 0 011.96-.367zm-10.222.184v2.162h-4.838v1.389h4.38v2.006h-4.38v1.456l4.904.003v2.16H35.57V7.575h7.327zm15.66 0l2.53.002v3.432h3.247V7.576h2.527l.001 9.175-2.528-.001-.002-3.486h-3.246v3.486h-2.53V7.575zm17.42.002l-.001 2.226-2.739.002v6.945h-2.53l.001-6.944-2.737-.003V7.578h8.005zm-29.117-.001l.001 9.174h-2.544l.002-9.174h2.54z"
      fill="#222"
    />
    <path
      d="M24.903.01l6.436.002-5.052 23.894h-6.436L24.903.01zm-4.457 0l-5.052 23.894-6.436.002L14.01.01h6.436zM6.608 13.95l-1.16 5.475H.003l1.157-5.476h5.448zM8.606 4.49L7.449 9.968l-5.447-.001 1.16-5.476h5.444z"
      fill="#F43545"
    />
  </svg>
);

import { CYAN, GREEN } from "shared/variables";
import { Footer } from "components/Footer";
import { Link } from "components/Link";
import { MessageBar } from "components/MessageBar";
import { Nav } from "components/Nav";
import { PageHead } from "components/PageHead";
import { useLocalStorage } from "shared/useLocalStorage";
import cx from "classnames";
import styles from "./Page.module.css";

export const Page = ({
  showCookieBar = true,
  children,
  color,
  description,
  preview,
  image,
  title,
  withLightFooter,
}) => {
  const [acceptsCookies, setAcceptsCookies] = useLocalStorage(
    "acceptsCookies",
    false
  );

  return (
    <div
      className={cx(styles.container, {
        [styles.green]: color === GREEN,
        [styles.cyan]: color === CYAN,
      })}
    >
      <PageHead
        title={title}
        description={description}
        image={image}
        acceptsCookies={acceptsCookies}
      />
      <Nav />
      <div className={styles.children}>{children}</div>
      <Footer isLight={withLightFooter} />
      {(!acceptsCookies || preview) && (
        <div className={styles.messageBarContainer}>
          {!acceptsCookies && showCookieBar && (
            <CookieBar
              onButtonClick={() =>
                setAcceptsCookies({
                  functional: true,
                  analytics: true,
                  social: true,
                })
              }
            />
          )}
          {preview && <PreviewBar />}
        </div>
      )}
    </div>
  );
};

const CookieBar = ({ onButtonClick }) => (
  <MessageBar
    buttonLabel="Akkoord"
    emoji="🍪"
    onButtonClick={onButtonClick}
    text={
      <span>
        Groundwork maakt gebruik van cookies om jou een optimale
        bezoekerservaring te bieden en jouw surfgedrag te meten.{" "}
        <Link url="/cookiesettings">Pas voorkeuren aan</Link>
      </span>
    }
  />
);

const PreviewBar = () => (
  <MessageBar
    buttonLabel="Preview sluiten"
    emoji="🚨"
    onButtonClick={() => {
      window.location.href = "/api/preview-exit";
    }}
    text="Je bekijkt een preview. Dit is niet hoe bezoekers de website zien."
  />
);
